import { PropTypes } from "aem/ComponentWrapper";

export default {
  title: "Trade Request Dialog",
  type: "rh/components/content/trade-request-dialog",
  isContainer: false,
  config: {
    emptyLabel: "Trade Request Dialog",
    isEmpty: (_: PropTypes) => false
  }
};
